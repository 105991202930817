<template>
  <j-abm
    name="courier"
    :suppliers="suppliers">
    <template #detail="{item}">
      <div class="row">
        <!-- <div class="col-lg-12 mb-4">
          <j-map :location="[-34.605344, -58.43289]" />
        </div> -->
        <div class="col-lg-12">
          <j-point
            icon="idcard"
            :title="$t('contact')"
            :point="item" />
        </div>
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <span class="font-size-12 text-uppercase">
                {{ $t('detail') }}
              </span>
            </div>
            <div class="card-body">
              <div class="mb-2">
                <a-icon type="check-circle" class="mr-1 text-secondary" />
                {{ $t(`enum.activation.${item.activation}`) }}
              </div>
              <div class="mb-2">
                <a-icon type="info-circle" class="mr-1 text-secondary" />
                {{ $t(`enum.courierType.${item.type}`) }}
              </div>
              <div class="mb-2">
                <a-icon type="car" class="mr-1 text-secondary" />
                {{ $t(`enum.vehicle.${item.vehicle}`) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </j-abm>
</template>

<script>
import lms from '@/api/lms'
import acl from '@/auth/acl'
import JAbm from '@/views/shared/abm/detail'
import JPoint from '@/views/shared/cards/point'
// import JMap from '@/views/shared/cards/map'
export default {
  name: 'Couriers',
  components: {
    JAbm,
    JPoint,
    // JMap,
  },
  data: function () {
    return {
      acl,
    }
  },
  computed: {
    suppliers() {
      return {
        fetchOne: id => lms.courier.fetchOne(id),
      }
    },
  },
}
</script>
