<template>
  <div class="main">
    <div v-if="item">
      <a-button
        class="p-0 mb-3"
        type="link"
        icon="arrow-left"
        size="large"
        @click="$router.go(-1)">
        {{ $t('back') }}
      </a-button>
      <div class="d-flex flex-wrap align-items-center mb-4">
        <div class="mr-auto mb-3">
          <div>
            <span class="user-select-all text-dark font-weight-bold font-size-48 align-middle">
              {{ item.contact.name }}
            </span>
          </div>
          <div>
            {{ $t(name) }}:
            <a-tag class="text-monospace">
              #{{ item.id }}
            </a-tag>
          </div>
        </div>
        <slot name="actions" v-bind="{item, type: 'button'}"/>
      </div>
      <slot name="detail" v-bind:item="item" />
    </div>
  </div>
</template>
<script>
import acl from '@/auth/acl'
export default {
  props: [
    'name',
    'suppliers',
  ],
  data: function () {
    return {
      acl,
      item: undefined,
    }
  },
  mounted() {
    this.suppliers.fetchOne(this.id).then(r => (this.item = r.data))
  },
  computed: {
    id() {
      return this.$route.params[this.name]
    },
  },
}
</script>
