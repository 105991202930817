<template>
  <div class="card">
    <div class="card-header">
      <span class="font-size-14 text-uppercase">
        <slot name="icon"/>
        {{ title }}
      </span>
      <h4 class="mt-2 text-dark">
        <span v-if="point.contact">{{ point.contact.name }}</span>
        <span v-else>--</span>
      </h4>
    </div>
    <div class="card-body">
      <div class="mb-2">
        <a-icon type="environment" class="mr-1 text-secondary" />
        <j-address :value="point.address" />
      </div>
      <div class="mb-2">
        <a-icon type="phone" class="mr-1 text-secondary" />
        <j-phone
          :value="point.contact.mobile"
          v-if="point.contact && point.contact.mobile" />
        <j-phone
          :value="point.contact.phone"
          v-else-if="point.contact && point.contact.phone" />
        <span v-else>
          {{ $t('msg.undefined', { name: $t('phone') }) }}
        </span>
      </div>
      <div class="mb-2">
        <a-icon type="mail" class="mr-1 text-secondary" />
        <j-email
          :value="point.contact.email"
          v-if="point.contact && point.contact.email" />
        <span v-else>
          {{ $t('msg.undefined', { name: $t('email') }) }}
        </span>
      </div>
      <div class="mb-2">
        <a-icon type="idcard" class="mr-1 text-secondary" />
        <span v-if="point.contact && point.contact.identification">
          DNI {{ point.contact.identification | mask }}
        </span>
        <span v-else>
          {{ $t('msg.undefined', { name: $t('identification') }) }}
        </span>
      </div>
      <div class="mb-2">
        <a-icon type="calendar" class="mr-1 text-secondary" />
        <span v-if="point.schedule">
          {{ point.schedule.max | datetime }}
        </span>
        <span v-else>
          {{$t('unscheduled')}}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { linkToGoogleMaps } from '@/utils/links'
import JEmail from '@/views/shared/labels/email'
import JPhone from '@/views/shared/labels/phone'
import JAddress from '@/views/shared/labels/address'
export default {
  props: {
    icon: String,
    title: String,
    point: Object,
  },
  components: {
    JEmail,
    JPhone,
    JAddress,
  },
  data: function () {
    return {
      linkToGoogleMaps,
    }
  },
}
</script>
