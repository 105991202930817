<template>
  <a v-if="link" :href="link" target="_blank">
    {{ pretty }}
  </a>
  <span v-else style="cursor: not-allowed">
    {{ pretty }}
  </span>
</template>

<script>
import { linkToGoogleMaps } from '@/utils/links'
export default {
  name: 'LabelAddress',
  props: [
    'value',
  ],
  computed: {
    pretty() {
      let text = this.value.street
      if (this.value.apartment) {
        text = `${text} ${this.value.apartment.toUpperCase()}`
      }
      if (this.value.city) {
        text = `${text}, ${this.value.city}, ${this.value.state}`
      }
      if (this.value.zip) {
        text = `${text} (${this.value.zip})`
      }
      return text
    },
    link() {
      return this.value.geocoded ? linkToGoogleMaps(this.value) : null
    },
  },
}
</script>
